<template>
    <base-modal :footer="false" mode="wide" classes="terminal-modal">
        <item-instruction :item="item" mode=""></item-instruction>
    </base-modal>
</template>

<script>

import ItemInstruction from "../shop/ItemInstruction";
export default {
    name: "ItemInstructionModal",
    components: {ItemInstruction},
    props: {
        item: {
            type: Object
        }
    }
}
</script>

